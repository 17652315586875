@font-face {
  font-family: "iconfont"; /* Project id 2686542 */
  src: url('//at.alicdn.com/t/font_2686542_piwwmzsxv.woff2?t=1626750790677') format('woff2'),
       url('//at.alicdn.com/t/font_2686542_piwwmzsxv.woff?t=1626750790677') format('woff'),
       url('//at.alicdn.com/t/font_2686542_piwwmzsxv.ttf?t=1626750790677') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-baohusan:before {
  content: "\e602";
}

.icon-qukuailian:before {
  content: "\e74a";
}

.icon-sifa:before {
  content: "\e61e";
}

.icon-chaxun:before {
  content: "\e673";
}

.icon-a-anquan1:before {
  content: "\e771";
}

@primary-color: #286ed0;