.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(144, 147, 153, 0.3);
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ededed;
  display: none;
}
@media screen and (max-width: 800px) {
  .footer {
    display: none;
  }
}
@media screen and (min-width: 800px) {
  .footer .copyright {
    width: 100%;
    text-align: center;
    background: #0d2b54;
    color: rgba(255, 255, 255, 0.8);
    height: 48px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
  .footer .copyright .beian-icon {
    width: 20px;
    height: 20px;
  }
  .footer .copyright .beian {
    color: rgba(255, 255, 255, 0.8);
    padding: 0 10px;
    text-decoration: none;
    font-size: 14px;
  }
}
