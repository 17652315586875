@import '../../style/iconfont.css';
.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(144, 147, 153, 0.3);
}
.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ededed;
  display: none;
}
@media screen and (max-width: 800px) {
  .home .banner {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  .home .banner .logo {
    width: 100%;
    padding: 10px 0;
    background: linear-gradient(#2A70D2, #2237A0);
  }
  .home .banner .logo img {
    display: block;
    width: 110px;
    margin: 0 auto;
  }
  .home .banner .number {
    color: #333;
    width: 100%;
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  .home .banner .number .label {
    font-size: 20px;
  }
  .home .banner .number .value {
    font-size: 30px;
  }
  .home .banner .number .value:nth-last-child(1) {
    border: none;
  }
  .home .banner .number .hash-label {
    display: none;
  }
  .home .banner .number .hash-value {
    display: none;
  }
  .home .introduced {
    width: 100%;
    margin: 0px auto 40px;
    padding: 0 20px;
  }
  .home .introduced .title {
    text-align: center;
    font-size: 30px;
    margin: 20px 0;
    color: #333;
  }
  .home .introduced .content {
    line-height: 36px;
    font-size: 16px;
    color: #333;
  }
  .home .introduced .characteristic {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .home .introduced .characteristic .c-item {
    width: 50%;
    flex-shrink: 0;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  .home .introduced .characteristic .c-item .icon {
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    border: 2px solid #45567d;
    background: rgba(63, 81, 181, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
  .home .introduced .characteristic .c-item .icon i {
    font-size: 40px;
    color: #45567d;
  }
  .home .introduced .characteristic .c-item .c-item-title {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
    color: #45567d;
  }
  .home .introduced .characteristic .c-item .c-i-c {
    font-size: 14px;
    color: #333;
  }
  .home .search {
    margin: 0px auto 40px;
    padding: 0 20px;
  }
  .home .search .title {
    text-align: center;
    font-size: 30px;
    margin: 40px 0;
    color: #333;
  }
  .home .search .form {
    width: 100%;
    margin: 0 auto;
  }
  .home .search .btns {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }
  .home .search .btns button {
    width: 100px;
  }
  .home .verification {
    margin: 0px auto 100px;
    padding: 0 20px;
  }
  .home .verification .title {
    text-align: center;
    font-size: 30px;
    margin: 40px 0;
    color: #333;
  }
}
@media screen and (min-width: 800px) {
  .home .banner {
    height: 562px;
    background: url(../../accets/images/bg.jpg) no-repeat, linear-gradient(#2A70D2, #2237A0);
    background-position: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
  }
  .home .banner .logo {
    width: 200px;
    position: absolute;
    left: 100px;
    top: 50px;
  }
  .home .banner .logo img {
    display: block;
    width: 100%;
  }
  .home .banner .number {
    color: #fff;
    width: 500px;
    margin-right: 200px;
  }
  .home .banner .number .label {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
  }
  .home .banner .number .value {
    padding: 0 10px 10px;
    word-wrap: break-word;
    word-break: normal;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  .home .banner .number .value:nth-last-child(1) {
    border: none;
  }
  .home .introduced {
    padding-top: 40px;
    width: 1000px;
    margin: 0px auto 100px;
  }
  .home .introduced .title {
    text-align: center;
    font-size: 30px;
    margin: 40px 0;
    color: #333;
  }
  .home .introduced .content {
    line-height: 36px;
    font-size: 16px;
    color: #333;
  }
  .home .introduced .characteristic {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
  }
  .home .introduced .characteristic .c-item {
    width: 150px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  .home .introduced .characteristic .c-item .icon {
    box-sizing: border-box;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    border: 2px solid #45567d;
    background: rgba(63, 81, 181, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
  .home .introduced .characteristic .c-item .icon i {
    font-size: 40px;
    color: #45567d;
  }
  .home .introduced .characteristic .c-item .c-item-title {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
    color: #45567d;
  }
  .home .introduced .characteristic .c-item .c-i-c {
    font-size: 14px;
    color: #333;
  }
  .home .search {
    margin: 0px auto 100px;
  }
  .home .search .title {
    text-align: center;
    font-size: 30px;
    margin: 40px 0;
    color: #333;
  }
  .home .search .form {
    width: 1000px;
    margin: 0 auto;
  }
  .home .search .btns {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }
  .home .search .btns button {
    width: 100px;
  }
  .home .verification {
    margin: 0px auto 100px;
  }
  .home .verification .title {
    text-align: center;
    font-size: 30px;
    margin: 40px 0;
    color: #333;
  }
}
